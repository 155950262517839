import React from 'react';
import * as Constants from '../actions/configuration';
import reactGA from 'react-ga';
import styles from './utils.module.scss';
import idConstants from '../data/idConstants';

const nodeRSA = require('node-rsa');

export const getPaymentPreference = (plan) => {
  if (plan.paymentPreference) {
    const publicKey = '-----BEGIN PUBLIC KEY-----\n' +
      plan.paymentPreference.key + '\n' +
      '-----END PUBLIC KEY-----\n';
    const decryptionKey = new nodeRSA(publicKey);
    return decryptionKey.decryptPublic(plan.paymentPreference.isRequired, 'utf8');
  } else {
    return false;
  }
};

export const validateUserLogin = (user) => {
  let isLoggedIn = false;
  if (user) {
    isLoggedIn = user?.roles?.includes(Constants.AAA_CONFIG.BUYER_ROLE);
  }
  return isLoggedIn;
};


export const setCookiePolicy = (expiryTime) => {
  document.cookie = 'cookieConsentByUser=1;expires=' + expiryTime + ';path=/;domain=' + location.hostname;
  // Initializing Google Analytics
  reactGA.initialize(Constants.GOOGLE_ANALYTICS_TRACKING_ID);
  reactGA.pageview(window.location.pathname);
};

export const unSetCookiePolicy = (expiryTime) => {
  document.cookie = 'cookieConsentByUser=0;expires=' + expiryTime + ';path=/;domain=' + location.hostname;
  window.location.reload();
};

//related to rich text editor
export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

export const isValidJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
};

const isUrlWithoutProtocolRegex = new RegExp(/^(?!(ftp|http|https))/);
export const LinkUrl = (props) => {
  const { url, target } = props?.contentState?.getEntity(props.entityKey).getData();
  return (
    <a href={isUrlWithoutProtocolRegex.test(url) ? '//' + url : url} target={target ? target : '_blank'} rel='noreferrer'>
      {props?.children}
    </a>
  );
};

export const myBlockStyleFn = (contentBlock) => {
  if (contentBlock?.getType().indexOf('header') !== -1)
    return `${styles['header-style']} ${styles[contentBlock?.getType()]}`;
};

export const regexValues = {
  urlRegex:
    '\\b(https?|ftp|file)://[a-zA-Z0-9]+[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]',
  numberRegex: '^[0-9]*$'
};

export const getOrderDetailDto = (orders) => {
  orders.map(order => {
    order.orderId = order.subscriptionId;
    order.orderDate = order.subscriptionStartDate;
    order.orderStatus = order.subscriptionStatus;
    return true;
  });
  return orders;
};

export const getEnvironment = (env) => {
  let environment = '';
  switch (env) {
    case Constants.REACT_APP_ENVIRONMENT.DEV:
      case Constants.REACT_APP_ENVIRONMENT.DEV_BEST: environment = 'development';
      break;
    case Constants.REACT_APP_ENVIRONMENT.QA: environment = 'qa';
      break;
    case Constants.REACT_APP_ENVIRONMENT.QA_INT: 
      case Constants.REACT_APP_ENVIRONMENT.QA_BEST: environment = 'qa-internal';
      break;
    case Constants.REACT_APP_ENVIRONMENT.STAGE:
      case Constants.REACT_APP_ENVIRONMENT.STAGE_BEST: environment = 'stage';
      break;
    case Constants.REACT_APP_ENVIRONMENT.PROD: environment = 'production';
      break;
    default: environment = 'development';
  }
  return environment;
};

export const getProductId = () => {
  const url = window.location.pathname;
  return url?.substring(url.lastIndexOf('/') + 1);
};

const getSubCategoryDetails = (urlString) => {
  const lookup = {
    'cloud-infrastructure': {
      id: idConstants.cloudInfrastructure.categoryId,
      name: idConstants.cloudInfrastructure.productCategoryName
    },
    'enterprise-licensing': {
      id: idConstants.enterpriseLicensing.categoryId,
      name: idConstants.enterpriseLicensing.productCategoryName
    },
    'managed-services': {
      id: idConstants.managedServices.categoryId,
      name: idConstants.managedServices.productCategoryName
    },
    'iot-software-services': {
      id: idConstants.iotSoftwareServices.categoryId,
      name: idConstants.iotSoftwareServices.productCategoryName
    },
    'professional-services': {
      id: idConstants.professionalServices.categoryId,
      name: idConstants.professionalServices.productCategoryName
    },
    'communication-services': {
      id: idConstants.communicationServices.categoryId,
      name: idConstants.communicationServices.productCategoryName
    },
    'sdk': {
      id: idConstants.sdk.categoryId,
      name: idConstants.sdk.productCategoryName
    }
  };

  return lookup[urlString] || '';
};

export const getCategoryDetails = () => {
  if (window.location.pathname.includes('/cloud-services/') || window.location.pathname.includes('/services/')) {
    return {
      id: getSubCategoryDetails(window.location.pathname.split('/')[2]).id,
      name: getSubCategoryDetails(window.location.pathname.split('/')[2]).name
    };
  }

  if (window.location.pathname.includes('/api/')) {
    return {
      id: idConstants.api.categoryId,
      name: idConstants.api.productCategoryName
    };
  }

  if (window.location.pathname.includes('/data/')) {
    return {
      id: idConstants.data.categoryId,
      name: idConstants.data.productCategoryName
    };
  }

  if (window.location.pathname.includes('/solutions/')) {
    return {
      id: idConstants.solutions.categoryId,
      name: idConstants.solutions.productCategoryName
    };
  }

  if (window.location.pathname.includes('/hardware/')) {
    return {
      id: idConstants.hardware.categoryId,
      name: idConstants.hardware.productCategoryName
    };
  }

  if (window.location.pathname.includes(idConstants.saas.url+'/')) {
    return {
      id: idConstants.saas.categoryId,
      name: idConstants.saas.productCategoryName
    };
  }

  if (window.location.pathname.includes(idConstants.paas.url+'/')) {
    return {
      id: idConstants.paas.categoryId,
      name: idConstants.paas.productCategoryName
    };
  }

  if (window.location.pathname.includes(idConstants.iaas.url+'/')) {
    return {
      id: idConstants.iaas.categoryId,
      name: idConstants.iaas.productCategoryName
    };
  }

  if (window.location.pathname.includes(idConstants.preDefinedWorkflow.url+'/')) {
    return {
      id: idConstants.preDefinedWorkflow.categoryId,
      name: idConstants.preDefinedWorkflow.productCategoryName
    };
  }

  if (window.location.pathname.includes(idConstants.customWorkflow.url+'/')) {
    return {
      id: idConstants.customWorkflow.categoryId,
      name: idConstants.customWorkflow.productCategoryName
    };
  }

  if (window.location.pathname.includes(idConstants.methods.url+'/')) {
    return {
      id: idConstants.methods.categoryId,
      name: idConstants.methods.productCategoryName
    };
  }
};

export const replaceSpaceWithHyphenInLowerCaps = (string) => string?.toLowerCase()?.replace(/ /g, '-');

export const hyphenatedStringToCamelCase = (string) => string?.replace(/-([a-z])/g, function (char) { return char[1]?.toUpperCase(); });

export const getDispatchObject = (type, dispatchObject, pushKey) => {
  const tempDispatchObject = { type: type };
  tempDispatchObject[pushKey] = dispatchObject;
  return tempDispatchObject;
};

export const getProposalStatusColor = (status) => {
  switch (status?.toUpperCase()) {
    case 'OPEN':
      return 'primary.main';
    case 'ACCEPTED':
      return 'success.main';
    case 'REVISION':
      return 'warning.main';
    case 'REJECTED':
      return 'error.main';
    default:
      return 'primary.main';
  }
};

export const isRatingAndReviewEnabled = (categoryId) => {
  const enabledCategoryIds = [
    idConstants.api.categoryId,
    idConstants.solutions.categoryId,
    idConstants.data.categoryId,
    idConstants.hardware.categoryId,
    idConstants.sdk.categoryId,
    idConstants.professionalServices.categoryId
  ];
  return enabledCategoryIds.includes(categoryId);
};
